import { usePrevious } from '@finn/auto-ui/hooks/usePrevious';
import {
  trackFilterOptionClicked,
  useTrackFilterCategoryClicked,
} from '@finn/auto-ui/modules/combinedFilters/tracking';
import { useAvailableFilters } from '@finn/auto-ui/modules/products/filters';
import { CTAData } from '@finn/auto-ui/types/UIModules';
import CTAs from '@finn/auto-ui/ui-modules/Hero2/CTAs';
import { FilterKey } from '@finn/ua-vehicle';
import { slugify } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SelectDropdown } from './SelectDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    cursor: 'pointer',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1120)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(1440)]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1120)]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    '& > div': {
      flex: 1,
    },
  },
}));

type Props = {
  primaryCTA: CTAData;
  secondaryCTA: CTAData;
  onSecondaryCTAClick: () => void;
};

type dropDownItemParams = {
  optionId: string;
  setOption: React.Dispatch<React.SetStateAction<string>>;
};

const HeroFilters: React.FC<Props> = ({
  primaryCTA,
  secondaryCTA,
  onSecondaryCTAClick,
}) => {
  const classes = useStyles();
  const i18n = useIntl();

  const [brand, setBrand] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [CTA, setCTA] = useState(primaryCTA);

  primaryCTA.metadata.text = i18n.formatMessage({
    id: 'general.heroFilters.cta',
  });

  const { brands } = useAvailableFilters({
    filters: {},
  });

  const prevBrand = usePrevious(
    brand,
    i18n.formatMessage({ id: 'general.heroFilters.allBrands' })
  );

  useEffect(() => {
    if (prevBrand !== brand) {
      setModel(null);
    }
  }, [brand]);

  useEffect(() => {
    const href = primaryCTA.metadata.href;
    const updatedHref = brand
      ? `${href}/${slugify(brand)}${model ? `_${slugify(model)}` : ``}`
      : href;
    setCTA({ ...CTA, metadata: { ...CTA.metadata, href: updatedHref } });
  }, [brand, model]);

  const handleClick = ({ setOption, optionId }: dropDownItemParams) => {
    setOption(optionId);
  };

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<{
        value: string | unknown;
      }>,
      setOption: React.Dispatch<React.SetStateAction<string>>,
      filterKey: FilterKey
    ) => {
      const option = e.target.value.toString();
      setOption(option);
      trackFilterOptionClicked(filterKey, option);
    },
    []
  );

  const buildRenderedItem = ({ setOption, optionId }: dropDownItemParams) => (
    <div
      key={optionId}
      className={classes.option}
      onClick={() => handleClick({ optionId, setOption })}
    >
      {optionId}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.dropdownContainer}>
        <SelectDropdown
          title={i18n.formatMessage({ id: 'general.heroFilters.brand' })}
          value={brand}
          placeholder={i18n.formatMessage({
            id: 'general.heroFilters.allBrands',
          })}
          onChange={(e) => handleChange(e, setBrand, FilterKey.BRANDS)}
          onOpen={useTrackFilterCategoryClicked(FilterKey.BRANDS)}
          options={brands.map((option) => ({
            value: slugify(option.id),
            label: option.id,
            renderItem: () =>
              buildRenderedItem({ optionId: option.id, setOption: setModel }),
          }))}
        />

        <SelectDropdown
          title={i18n.formatMessage({ id: 'general.heroFilters.model' })}
          disabled={!brand}
          value={model}
          placeholder={i18n.formatMessage({
            id: 'general.heroFilters.allModels',
          })}
          onOpen={useTrackFilterCategoryClicked(FilterKey.MODELS)}
          onChange={(e) => handleChange(e, setModel, FilterKey.MODELS)}
          options={brands
            .find((brandItem) => slugify(brandItem.id) === brand)
            ?.models.map((option) => ({
              value: slugify(option.id),
              label: option.id,
              renderItem: () =>
                buildRenderedItem({
                  optionId: option.id,
                  setOption: setModel,
                }),
            }))}
        />
      </div>

      <CTAs
        primaryCTA={CTA}
        secondaryCTA={secondaryCTA}
        onSecondaryCTAClick={onSecondaryCTAClick}
        fullWidth={true}
      />
    </div>
  );
};

export default HeroFilters;
