import { ExpandMore } from '@finn/design-system/icons/expand-more';
import { cn } from '@finn/ui-utils';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';

interface DropdownOption {
  value: string | number;
  label: string;
  renderItem?: () => React.ReactNode;
}

interface SelectDropdownProps extends Omit<SelectProps, 'onChange'> {
  value: string | number;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  options: DropdownOption[];
  title: string;
  onOpen: any;
}

// TODO: This is temporary hack to avoid using colors outside of tailwind
// this code should be deleted during migration ot Select component from new DS
// sadly, right now I can not find better solution, as MUI@4 Select does not allow to
// style notchedOutline via className
export const unsafe_tailwind_mui_overrides = `
  [&_fieldset]:border [&_fieldset]:border-solid [&_fieldset]:border-pearl
  [&.Mui-disabled_.MuiOutlinedInput-notchedOutline]:!border-pearl
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      marginBottom: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.up(1440)]: {
        width: '160px',
      },
      height: 56,
      borderRadius: 2,
      paddingTop: 14,
      fontSize: 16,
    },
    item: {
      width: '100%',
      padding: '10px 16px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:last-child': {
        borderBottom: 'none',
      },
      '&.Mui-disabled': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    inputLabel: {
      top: 16,
    },
    chevronIcon: {
      position: 'absolute',
      right: 10,
      top: 18,
      pointerEvents: 'none',
    },
  })
);

export const SelectDropdown: FC<SelectDropdownProps> = ({
  value,
  onChange,
  options,
  title,
  placeholder = '',
  onOpen,
  ...selectProps
}) => {
  const classes = useStyles();

  const renderValue = (selected: string | number) => {
    const selectedOption = options?.find((option) => option.value === selected);

    return selectedOption ? selectedOption.label : placeholder;
  };

  const [mh, setmh] = useState(null);
  useEffect(() => {
    const selectRef = document.getElementById('selectcontainer');
    const position = selectRef?.getBoundingClientRect().bottom;
    setmh(window.innerHeight - position);
  }, []);

  return (
    <div id="selectcontainer">
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel shrink={true} className={classes.inputLabel}>
          {title}
        </InputLabel>
        <Select
          onOpen={onOpen}
          displayEmpty
          value={value}
          onChange={onChange}
          renderValue={renderValue}
          className={cn(classes.dropdown, unsafe_tailwind_mui_overrides, {
            '!bg-snow !text-black': selectProps.disabled,
          })}
          IconComponent={() => <ExpandMore className={classes.chevronIcon} />}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
            PaperProps: { style: { maxHeight: mh } },
          }}
          {...selectProps}
        >
          <MenuItem value="">{placeholder}</MenuItem>
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={cn(
                classes.item,
                '!border-pearl hover:bg-cotton !border-b !border-solid'
              )}
              classes={{
                selected: '!bg-snow',
              }}
            >
              {option.renderItem ? option.renderItem() : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
