import Hero2, { Hero2CosmicMetadata } from '@finn/auto-ui/ui-modules/Hero2';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import React, { useCallback } from 'react';

import { PrequalifyModal } from './PrequalifyModal';

interface IProps {
  data: Hero2CosmicMetadata;
}

const HomeHero2: React.FunctionComponent<IProps> = ({ data }) => {
  const openModal = useOpenModal();

  const handleSecondaryCTAClick = useCallback(() => {
    openModal(ModalKey.PREQUALIFY);
  }, [openModal]);

  return (
    <>
      <Hero2 data={data} onSecondaryCTAClick={handleSecondaryCTAClick} />
      <ModalContainer
        modalKey={ModalKey.PREQUALIFY}
        ModalComponent={PrequalifyModal}
      />
    </>
  );
};

export default React.memo(HomeHero2);
