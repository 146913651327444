import { ButtonLink } from '@finn/atoms';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@finn/atoms/Modal';
import {
  getCosmicObjectWithApi,
  PrequalifyFormCosmicMetadata,
} from '@finn/ui-cosmic';
import { parseToHtml, useCurrentLocale } from '@finn/ui-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, Theme } from '@material-ui/core';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  FormInputs,
  FormWrapper,
  initialValues,
  validationSchema,
} from '~/ui-modules/ContactForm/PrequalifyForm';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginTop: theme.spacing(-2),
    fontWeight: 300,
  },
}));

interface IProps {
  open: boolean;
  onClose?(): void;
}

export const PrequalifyModal: React.FunctionComponent<IProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const { locale } = useCurrentLocale();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<PrequalifyFormCosmicMetadata | null>(
    null
  );

  useEffect(() => {
    const getCosmicFormData = async () => {
      const cosmicForm = await getCosmicObjectWithApi({
        slug: 'form-prequalify',
        locale,
      });
      setFormData(cosmicForm.metadata);
    };

    getCosmicFormData();
  }, []);

  const methods = useForm<FormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const {
    formState: { isSubmitSuccessful },
  } = methods;

  if (!formData) return;

  return (
    <Modal size="xl" open={open} onClose={onClose} type="alert">
      <ModalHeader>
        {parseToHtml(
          isSubmitSuccessful ? formData.title_success : formData.title
        )}
      </ModalHeader>

      <ModalContent>
        <Box className={classes.description}>
          {parseToHtml(
            isSubmitSuccessful
              ? formData.description_success
              : formData.description
          )}
        </Box>

        {!isSubmitSuccessful && (
          <FormWrapper methods={methods} cosmicData={formData.form_type} />
        )}
      </ModalContent>

      {isSubmitSuccessful && (
        <ModalFooter>
          <Link href="/en-US/subscribe" passHref>
            <ButtonLink
              label="Discover our cars"
              size="large"
              onClick={() => setLoading(true)}
              loading={loading}
            />
          </Link>
        </ModalFooter>
      )}
    </Modal>
  );
};
